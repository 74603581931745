import React from 'react'
import VDiv from '../containers/vdiv'
import HDiv from '../containers/hdiv'
import { useParams, useNavigate } from "react-router-dom"
import { getTabNavigationUrl } from '../../Constant'


export default function TabBar({selectedIndex = 1}) {

    const navigate = useNavigate()

    const { tenantId, roomId } = useParams()

    const navigateTo = (url) => {
        navigate(getTabNavigationUrl(tenantId, roomId, url))
    }

    return (
        <HDiv className='flex tab-bar'>
            <VDiv className="tab" onClick={() => navigateTo("")}>
                <img src={selectedIndex === 1 ? "/assets/images/icons/tabs/HomeIconSelected.png" :"/assets/images/icons/tabs/HomeIcon.png"} alt='Home' />
                <span className={selectedIndex === 1 ? "selected" : ""}>Home</span>
            </VDiv>
            <VDiv className="tab" onClick={() => navigateTo("service-history")}>
                <img src={selectedIndex === 2 ? "/assets/images/icons/tabs/CoursesIconSelected.png" :"/assets/images/icons/tabs/CoursesIcon.png"}  alt='Service History' />
                <span className={selectedIndex === 2 ? "selected" : ""}>History</span>
            </VDiv>
            <VDiv className="tab" onClick={() => navigateTo("explore")}>
                <img src={selectedIndex === 3 ? "/assets/images/icons/tabs/ExploreIconSelected.png" :"/assets/images/icons/tabs/ExploreIcon.png"} alt='Explore' />
                <span className={selectedIndex === 3 ? "selected" : ""}>Explore</span>
            </VDiv>
            <VDiv className="tab" onClick={() => navigateTo("chat")}>
                <img src={selectedIndex === 4 ? "/assets/images/icons/tabs/ChatIconSelected.png" :"/assets/images/icons/tabs/ChatIcon.png"} alt='Chat' />
                <span className={selectedIndex === 4 ? "selected" : ""}>Chat</span>
            </VDiv>
            <VDiv className="tab" onClick={() => navigateTo("more")}>
                <img src={selectedIndex === 5 ? "/assets/images/icons/tabs/MoreIconSelected.png" :"/assets/images/icons/tabs/MoreIcon.png"} alt='More' />
                <span className={selectedIndex === 5 ? "selected" : ""}>More</span>
            </VDiv>
        </HDiv>
    )
}