import React from 'react'
import PagePopup from '../containers/page-popup'



export default function FormPopup({onBack, onSave, onCancel, children, title}) {
    return(
        <PagePopup onBack={onCancel} showHeader showBackNavigation title={title}>
            {children}
            <div className='form-footer mt-3'>
                <button type="button" className="mb-3 col-sm-6 btn btn-outline-secondary" onClick={() => onCancel()} data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="mb-3 col-sm-6 btn btn-primary" onClick={() => onSave(false)}>Save</button>
            </div>
        </PagePopup>
    )
}