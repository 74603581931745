import { useEffect, useState } from "react";
import { getProductRequestDetail, getProductRequestStatuses } from "../service/ProductRequestService";
import { useParams } from "react-router";

const useProductRequestDetailSection = () => {
    const { staffId, id } = useParams();

  const [statuses, setStatuses] = useState([]);

  const [message, setMessage] = useState("");

  const [showMessage, setShowMessage] = useState(false);

  const [serviceRequest, setServiceRequest] = useState(undefined);

  const [showForm, setShowForm] = useState(false);

  const fetchServiceRequest = async () => {
    const response = await getProductRequestDetail(staffId, id);
    setServiceRequest(response);
  };

  const fetStatuses = async () => {
    const response = await getProductRequestStatuses(
      staffId,
      localStorage.getItem("tenantId")
    );
    setStatuses(response);
  };

  const displayMessage = (message) => {
    setMessage(message);
    setShowMessage(true);
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const onUpdate = async () => {
    setShowForm(false);
    displayMessage("Status has been Updated Successfully");
    fetchServiceRequest();
  };

  const onCancel = () => {
    setShowForm(false);
  };

  useEffect(() => {
    fetchServiceRequest();
    fetStatuses(); // eslint-disable-next-line
  }, [id]);

  return {
   onUpdate,
   onCancel,
   showForm,
   serviceRequest,
   showMessage,
   message,
   statuses,
   setShowForm
  }
};

export default useProductRequestDetailSection;
