import React from "react";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  //   const navigate = useNavigate();
  let token = localStorage.getItem("token");
  if (token) {
    return <>{children}</>;
  } else {
    return <Navigate to="/" replace />;
  }
};
export default RequireAuth;
