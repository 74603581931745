import SearchBox from "../components/layout/search-box";
import HSpacer from "../components/elements/h-spacer";
import VDiv from "../components/containers/vdiv";
import Page from "../components/general/page";
import TabBar from "../components/layout/tab-bar"

export default function Explore() {  
    return (
      <Page showHeader title={"Packages and Services"} >
        <VDiv>
          <SearchBox placeholder={"Search packages..."} />
          <HSpacer size="lg" />
        </VDiv>
        <TabBar selectedIndex={3}/>
      </Page>
    );
  }