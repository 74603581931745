import {
    getServiceRequestApiUrl,
    getServiceRequestDetailApiUrl,
    getServiceRequestStatusesApiUrl,
    getServiceRequestByStatusApiUrl,
    getUpdateServiceRequestStatusApiUrl,
    getServiceRequestNewApiUrl
} from "./ApiUrls";
import { get, put } from "./Service";

export const getServiceRequests = async (staffId) => {
    const response = await get(getServiceRequestApiUrl(staffId));
    if (!response) {
        return []
    }
    return response;
};
export const getServiceRequestByStatus = async (staffId, id) => {
    const response = await get(getServiceRequestByStatusApiUrl(staffId, id));
    if (!response) {
        return []
    }
    return response;
};
export const getServiceRequestsNew = async (staffId) => {
    const response = await get(getServiceRequestNewApiUrl(staffId));
    if (!response) {
        return []
    }
    return response;
};


export const getServiceRequestDetail = async (staffId, id) => {
    const response = await get(getServiceRequestDetailApiUrl(staffId, id));
    return response;
};

export const getServiceRequestStatuses = async (staffId, tenantId) => {
    const response = await get(getServiceRequestStatusesApiUrl(staffId, tenantId));
    return response;
};

export const updateStatus = async (staffId, data) => {
    const response = await put(getUpdateServiceRequestStatusApiUrl(staffId), data);
    return response;
};




