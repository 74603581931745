import React from "react";
export const toaster = (status, msg, body) => {
  document.getElementById("liveToast").classList.replace("hide", "show");
  document.getElementById("toast-msg-title").innerHTML = msg;
  if (body !== undefined && body !== null) {
    document.getElementById("toast-msg-body").innerHTML = `${body.substring(
      0,
      30
    )}...`;
  } else {
    document.getElementById("toast-msg-body").innerHTML =
      `New Request for ${msg}`.substring(0, 30);
  }
  if (status === "success") {
    // document.getElementById("toast-img").setAttribute("src", "./assets/img/icons/unicons/cc-success.png")
    document.getElementById("toast-msg-title").style.color = "green";
  }
  if (status === "error") {
    // document.getElementById("toast-img").setAttribute("src", "./assets/img/icons/unicons/cc-warning.png")
    document.getElementById("toast-msg-body").style.color = "red";
  }
  // setTimeout(() => {
  //     document.getElementById("liveToast").classList.replace("show", "hide")
  // }, 11111)
};
export default function Toast() {
  return (
    <div className="position-fixed top-0 start-100  p-3 alert-msg z-index-1212 ">
      <div
        id="liveToast"
        className="toast hide w-auto px-4 bg-white text-nowrap "
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header flex-column">
          {/* <img src="" className="rounded me-2" id="toast-img" alt="" width={20} height={20} /> */}
          <strong className="me-auto" id="toast-msg-title"></strong>
          <strong className="me-auto" id="toast-msg-body"></strong>
          {/* <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button> */}
        </div>
      </div>
    </div>
  );
}
