import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import VDiv from "../containers/vdiv";

import {
  getServiceRequestDetail,
  getServiceRequestStatuses,
} from "../../service/ServiceRequestService";

import PageTitle from "../elements/texts/page-title";
import { getFormattedDateAndTime } from "../../utils/formatters";
import HDiv from "../containers/hdiv";
import HGrow from "../elements/h-grow";
import Toaster from "../general/toast";
import RequestPopup from "../popups/request-popup";

export default function ServiceRequestDetailSection() {
  const { staffId, id } = useParams();

  const [statuses, setStatuses] = useState([]);

  const [message, setMessage] = useState("");

  const [showMessage, setShowMessage] = useState(false);

  const [serviceRequest, setServiceRequest] = useState(undefined);

  const [showForm, setShowForm] = useState(false);

  const fetchServiceRequest = async () => {
    const response = await getServiceRequestDetail(staffId, id);
    setServiceRequest(response);
  };

  const fetStatuses = async () => {
    const response = await getServiceRequestStatuses(
      staffId,
      localStorage.getItem("tenantId")
    );
    setStatuses(response);
  };

  const displayMessage = (message) => {
    setMessage(message);
    setShowMessage(true);
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const onUpdate = async () => {
    setShowForm(false);
    displayMessage("Status has been Updated Successfully");
    fetchServiceRequest();
  };

  const onCancel = () => {
    setShowForm(false);
  };

  useEffect(() => {
    fetchServiceRequest();
    fetStatuses(); // eslint-disable-next-line
  }, [id]);

  
  return (
    <VDiv>
      {showMessage && <Toaster text={message} className="success" />}
      {serviceRequest && (
        <>
          <VDiv className={"row"}>
            <HDiv>
              <PageTitle text={serviceRequest.service.name} />
              <HGrow />
              <button
                className={`edc-btn-secondary`}
                onClick={() => setShowForm(true)}
                disabled={serviceRequest?.status?.name === "COMPLETED"}

              >
                Update
              </button>
            </HDiv>
            <p>{serviceRequest.comment} </p>
            <div className="timeline timeline-one-side mt-3">
              {serviceRequest.histories.map((item) => (
                <StatusBlock key={item.id} item={item} />
              ))}
            </div>
          </VDiv>
          {showForm && (
            <RequestPopup
              title={serviceRequest.service.name}
              serviceRequest={serviceRequest}
              onCancel={onCancel}
              statuses={statuses}
              onSave={onUpdate}
            />
          )}
        </>
      )}
    </VDiv>
  );
}

function StatusBlock({ item }) {
  const [openFile, setOpenFile] = useState(false);

  const [selectedFile, setSelectedFile] = useState();

  const onFileClick = (file) => {
    setSelectedFile(file);
    setOpenFile(true);
  };

  const closeFile = (event) => {
    event.stopPropagation();
    setOpenFile(false);
  };

  return (
    <div className="timeline-block mb-3">
      <span className="timeline-step">
        <img src="/assets/images/icons/completed-icon.png" alt="Done" />
      </span>
      <div className="timeline-content">
        <h6 className="text-dark text-sm font-weight-bold mb-0">
          {item?.status?.displayName}
        </h6>
        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
          {getFormattedDateAndTime(new Date(item.createdOn))}
        </p>
        {item.comment && <p className="comment">{item.comment}</p>}
        <HDiv className="mt-3 mb-3">
          {item.attachments.map((attachment) => (
            <VDiv
              onClick={() => onFileClick(attachment)}
              className="file-preview"
              key={attachment.id}
              style={{
                backgroundImage: `url(${attachment.attachment.mediaUrl})`,
              }}
            ></VDiv>
          ))}
        </HDiv>
      </div>

      {openFile ? (
        <VDiv className="show-file-container">
          <img
            className="file"
            alt={selectedFile.attachment.name}
            src={selectedFile.attachment.mediaUrl}
          />
          <img
            className="close-btn"
            src="/assets/images/icons/icon-close-white.png"
            alt="close"
            onClick={(event) => closeFile(event)}
          />
        </VDiv>
      ) : null}
    </div>
  );
}
