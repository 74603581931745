import { useState } from "react";
import { useParams } from "react-router-dom";
import FormPopup from "./form-popup";
import TextArea from "../inputs/textarea";
import { getUploadApiUrl } from "../../service/ApiUrls";
import { upload } from "../../service/Service";
import FileSelector from "../inputs/file-selector";
import ProcessLoader from "../general/process-loader";
import { updateStatus } from "../../service/ServiceRequestService";
import StatusSelectGroup from "../inputs/status-elect-group";
import { updateProductStatus } from "../../service/ProductRequestService";

export default function RequestPopup({
  statuses,
  serviceRequest,
  onSave,
  onCancel,
  title,
}) {
  const { staffId } = useParams();

  const [files, setFiles] = useState([]);

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [apiInProgress, setApiInProgress] = useState(false);

  const [comment, setComment] = useState("");

  const [selectedStatus, setSelectedStatus] = useState(
    serviceRequest.status.id
  );


  
  const onFileSelect = (files) => {
    setFiles(files);
  };

  const handleInputChange = (event) => {
    event.stopPropagation();
    if (event.target.name === "comment") {
      setComment(event.target.value);
    }
    if (event.target.name === "status") {
      setSelectedStatus(event.target.value);
    }
  };

  const handleSubmit = async (data) => {
    setApiInProgress(true);
    let attachments = [];
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let attachment = await uploadFile(files[i]);
        attachments.push({ attachment });
      }
    }
    let histories = [];
    histories.push({ attachments, comment: data.comment });
    const payload = {
      id: serviceRequest.id,
      status: { id: selectedStatus },
      doneBy: { id: localStorage.getItem("userId") },
      attachments: attachments,
      comment: comment,
    };
    serviceRequest.product === undefined?await updateStatus(staffId, payload):await updateProductStatus(staffId, payload)
    setApiInProgress(false);
    if (onSave) {
      onSave(serviceRequest);
    }
  };

  const uploadFile = async (file) => {
    setUploadInProgress(true);
    const data = {
      staffId,
      locationId: localStorage.getItem("location"),
      folderName: staffId,
      roomId: staffId,
      userId: localStorage.getItem("userId"),
      file: file.file,
    };
    let response = await upload(getUploadApiUrl(staffId), data);
    setUploadInProgress(false);
    if (response) {
      return response;
    }
  };

  return (
    <FormPopup title={title} onSave={handleSubmit} onCancel={onCancel}>
      <StatusSelectGroup
        className="mb-3 col-md-12"
        statusvalue={selectedStatus}
        name="status"
        label="Select Service"
        list={statuses}
        valueProperty="id"
        onChange={handleInputChange}
      />
      <TextArea
        value={comment}
        className="mb-3 col-md-12"
        onChange={handleInputChange}
        label="Notes/Comments"
        name="comment"
        placeHolder="Enter your notes/comments"
      />
      <FileSelector
        onFileSelect={onFileSelect}
        label="Upload Photos"
        showProgress={uploadInProgress}
        multiple
      />
      {apiInProgress && <ProcessLoader />}
    </FormPopup>
  );
}
