import React from "react";
import HDiv from "../containers/hdiv";

export default function StatusCard({ item, onClick, selected = false }) {

  
  return (
    <HDiv
      className={`edc-card image-pop-zoom-up ${selected ? " selected" : ""}`}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <p>{item?.displayName}</p>

    </HDiv>
  );
}
