import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// import SearchBox from "../components/layout/search-box";
import HSpacer from "../components/elements/h-spacer";
import VDiv from "../components/containers/vdiv";
import Page from "../components/general/page";
import BgOverlay from "../components/elements/bg-overlay";
import HDiv from "../components/containers/hdiv";
import HGrow from "../components/elements/h-grow";
import Text from "../components/elements/texts/text";
import ServiceRequestHistory from "../components/sections/service-request-history";
import ConfirmPopupModal from "../components/popups/confirmationPopup";
import { AppState } from "../components/state";
import ProductRequestHistory from "../components/sections/product-request-history";
import { getAllNotificationUrl } from "../service/ApiUrls";
import axios from "axios";

function Header({ title, subTitle, setLogoutState, refresh, setRefresh }) {

  const handleClickLogout = () => {
    if (notifyStatus === false) {
      document.querySelector(".maxout").classList.add("show");
      setNotifyStatus(true);
    } else {
      document.querySelector(".maxout").classList.remove("show");
      setNotifyStatus(false);
    }
  };
  const handleUser = (event) => {
    setLogoutState(true);
  };
  const {  setAppData } = useContext(AppState);
  // const [message, setMessage] = useState([]);
  // useEffect(() => {
  //   if (appData !== undefined) {
  //     if (appData.length > 0) {
  //       setMessage((prevNotifications) => [...prevNotifications, appData]);
  //     }
  //   }
    // eslint-disable-next-line
  // }, [appData]);

  
  useEffect(() => {
    setTimeout(() => {
      getNotification();
    }, 1000);
        // eslint-disable-next-line
  }, [localStorage.getItem("userId")]);
  const [notifications, setNotifications] = useState([]);

  const getAllNotification = async () => {
    const response = await axios.get(getAllNotificationUrl(localStorage.getItem("userId")));

    return response;
  };

  const getNotification = async () => {
    const id = localStorage.getItem("userId");
    const response = await getAllNotification(id);
    setNotifications(response.data.list);
  };
  const handleContext = () => {
    setAppData();
    // setMessage([]);
    setNotifyStatus(true);
    setRefresh(!refresh);
  };
  const [notifyStatus, setNotifyStatus] = useState(false);
  const handleNotifyMessage = () => {
    if (notifyStatus === false) {
      document.querySelector(".max").classList.add("show");
      setNotifyStatus(true);
    } else {
      document.querySelector(".max").classList.remove("show");
      setNotifyStatus(false);
    }
  };
  const handleClosePopup = () => {
    document.querySelector(".max").classList.remove("show");
  };

  
  return (
    <VDiv className="page-header">
      <BgOverlay />
      <HDiv className="brand">
        <img
          className="logo"
          src="../assets/images/logo-rounded-alt.png"
          alt="GJ Concierge"
        />
        <VDiv className="welcome-msg grow">
          <Text
            text={title ? title : "Hello User!"}
            size="mg"
            className="user-info white"
          />
          <Text
            text={subTitle ? subTitle : "Welcome to GJ Concierge"}
            size="sm"
            className="brand-info white"
          />
        </VDiv>
        <HGrow />
        <VDiv className={"header-icon-menu"}>
          <VDiv className="menus nav-item dropdown">
            <div
              className="nav-link text-body p-0"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={handleNotifyMessage}
            >
              {notifications && notifications.length > 0 ? (
                <div className="notification-icon">
                  <span className="menu-item">
                    <img
                      className="icon"
                      alt="Notifications"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACsElEQVRoQ+2ZgVECMRBFuQrUCoQKlAqECtQKxA6wArEC7UCoQKlArECoQKhAOsD/Z3IzRybJbnI5GGZuZ24YvNzuf9nNJodF58StOHH9nRbg2BlsJAO73a4LsGdcdwbwE58vRVGscwNnBzDifyD03BK7xfd+boisABB/DZHvuPjpsiX++AgIfmaxLAAQ/gA1E1wsHY2xlCYAmWkGh8bUAjDlwhkfJApZmIwQKMmSASCeoj9w2bUeK4Rr4x7ZIEy0JQGYWv/yiGdZUMyyrHUznuuCXenWoZIQw5S1EQ0Q6DJziBhLXcY8/+YASepSKQCcebvmnyCcotQGkDEGv1oPLOBnqHaCgVEACDrCM1y0VYsWXz7sgWCbnWohYgF+4bhbcT5HsHK31cbcGwcI7tLVdcG109c6UwOYhcgdtmo9qeYlIWZNcGKqxh1btdnFAEwQgeeb0mYIwpKqbY4s8NzEeKLFACzg7abiMapWQ0oca+sbAANRPQbEANj1r06zJMRRnmsA9KTneD8GYFd1iADqZzVCAJHkXy0iNYBGPMek+m8BtDMsjWsz0NQMSX7L+20GpJlKnSHJ70Ey4DqFNr0PAEy104tt1HOE3gCgq51dzTjE4QvNmTVWhAgCeMQzBt9heQzOZojFYznfsW0LQngBUh3WIUqZsBDAH8TYvziIKa0DYI4UI3zab31bZPzC5TsEsHe40i6qugA+CF/TUAPk7joSqLZtqwGkgE3fr52BpgVK/lMAXH1ZitPUfe++EyqhAdRMcV02pUrpd4NxI2RgEdWFlM6PPkw8ShxdoSAgG0BgF3VJyLYhZgMwGxB/TbsSJm2Fevb9Cyo64bkBePTgYvNBrHBvAAB2uCyWFaBUZMpphO/lTDMzUwhnV8tqjQBkVXioRXxI0dVYbQaONfNl3H8duC5Ahiw7wwAAAABJRU5ErkJggg=="
                    />
                  </span>
                  <div className="notification-dot"></div>
                </div>
              ) : (
                <span className="menu-item">
                  <img
                    className="icon"
                    alt="Notifications"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACsElEQVRoQ+2ZgVECMRBFuQrUCoQKlAqECtQKxA6wArEC7UCoQKlArECoQKhAOsD/Z3IzRybJbnI5GGZuZ24YvNzuf9nNJodF58StOHH9nRbg2BlsJAO73a4LsGdcdwbwE58vRVGscwNnBzDifyD03BK7xfd+boisABB/DZHvuPjpsiX++AgIfmaxLAAQ/gA1E1wsHY2xlCYAmWkGh8bUAjDlwhkfJApZmIwQKMmSASCeoj9w2bUeK4Rr4x7ZIEy0JQGYWv/yiGdZUMyyrHUznuuCXenWoZIQw5S1EQ0Q6DJziBhLXcY8/+YASepSKQCcebvmnyCcotQGkDEGv1oPLOBnqHaCgVEACDrCM1y0VYsWXz7sgWCbnWohYgF+4bhbcT5HsHK31cbcGwcI7tLVdcG109c6UwOYhcgdtmo9qeYlIWZNcGKqxh1btdnFAEwQgeeb0mYIwpKqbY4s8NzEeKLFACzg7abiMapWQ0oca+sbAANRPQbEANj1r06zJMRRnmsA9KTneD8GYFd1iADqZzVCAJHkXy0iNYBGPMek+m8BtDMsjWsz0NQMSX7L+20GpJlKnSHJ70Ey4DqFNr0PAEy104tt1HOE3gCgq51dzTjE4QvNmTVWhAgCeMQzBt9heQzOZojFYznfsW0LQngBUh3WIUqZsBDAH8TYvziIKa0DYI4UI3zab31bZPzC5TsEsHe40i6qugA+CF/TUAPk7joSqLZtqwGkgE3fr52BpgVK/lMAXH1ZitPUfe++EyqhAdRMcV02pUrpd4NxI2RgEdWFlM6PPkw8ShxdoSAgG0BgF3VJyLYhZgMwGxB/TbsSJm2Fevb9Cyo64bkBePTgYvNBrHBvAAB2uCyWFaBUZMpphO/lTDMzUwhnV8tqjQBkVXioRXxI0dVYbQaONfNl3H8duC5Ahiw7wwAAAABJRU5ErkJggg=="
                  />
                </span>
              )}
            </div>
            <div
              className="nav-link text-body p-0"
              id="dropdownMenuButtons"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={(event) => handleClickLogout(event)}
            >
              <span className="menu-item">
                <img
                  className="icon"
                  alt="User"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAC20lEQVRoQ+1Yi1ECMRDlKtAOxAqUCsQK1ArUCoQOsAK1ArUCpQKxArUCoQOtAN9zcjNrzHcTB25kZ26AI5t9L7ubbLbpdVyajuPvbQis2oPVPbBcLk9Baohn3zzk+Gqex6ZppjVJVyMA4McAdoWnHwE4x/9jEHmsQaQKAYAn8FEmoGuQGGfq/BpeTADgrzHrhRLIDUjkEv9hqoiACZsHB/h7vOMKM/Z7GMd8IFDmhy0nJeFUSuDdivlP/B62wG2khsgM77fEf3OM31V6UH8OAMwZjN5ahgc+8O04Q+LF0lN7Qe0BALmzQiI5nh269yDOBcmWEgJcRcZ2K9HVD3jhFQQG2eihUEJgKQ0CQNZc8EKRfms7y6gEXAqgVL8GAW6Re5VC6A0OlOGYHE0lHuh8End7G6WPEcdzfOwIf3/g+2HkIHvCmG2hs8D4fnLMWAPVIWQIsAJ1lRIML54LspRgvUSv2aI+xDhREQFDorvFXLuUyoo0+eQOhVexBwQJhhO9IXPCZXuBl6OSClROWo2ARYRkuK+358QbvjMfeKWschNr7VUnoN1NtHr/m4Cp7XnLYrhwb08tBxhOPDNmeKaxO0T1JDatkwkm7mtdb+nN8VvVqcgKIQAnYN7ChpWA29PQI+fwCAklSTIBgCdonrqyDEgykjkoWI7YcyURMOBZw7iEHQiuHG9V36VDTEzuMF+43R55xifd8KIETNjw+mivPFuEPJCS3e0CaubnAWgToSdIIjh/CgGuPMNHChOORqsJiLBvxA6flBnsHIaMBAl4WifVwbcAPSSC1WqMgN244p7NuP0zAQmWGjKcgo0vLwFPA2o3FpOlzExOcOGkeBM6RMCu89XNp1xSDi9cYuEmrnlCBGZQOBBKPGDucsFoxjty7xm2h7kE1J03DWip4whfb+cu5IEqnTMtmdTG14aAdoVjesUeiBlYl/+jpcS6APXh2BBYtYc2Hli1B74AqTg2QBc21X0AAAAASUVORK5CYII="
                />
              </span>
            </div>

          </VDiv>

          <VDiv className="position-absolute mt-5 me-5">

          </VDiv>
        </VDiv>
      </HDiv>
      <ul
        className="dropdown-menu  row dropdown-menu-end  gap-3  px-2 py-3 me-sm-n4 h-25vh overflow-auto max h-25vh overflow-auto  z-index-2000 popup-notification"
        aria-labelledby="dropdownMenuButton"
      >
        <div className="d-flex">
          <div>
            {notifications && notifications.length > 0 ? (
              notifications.map((item, index) => (
                <li
                  key={index}
                  className="mb-2 d-flex flex-column"
                  onClick={() => handleContext()}
                >
                  <div
                    className="dropdown-item border-radius-md"
                  // href={`#/t/${localStorage.getItem("tenantId")}/l/${localStorage.getItem("locationId")}/service-requests`}
                  >
                    <div className="d-flex py-1">
                      <div className="my-auto"></div>
                      <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-sm font-weight-normal mb-1 color-gray">
                              <span className="bold">
                                {item.subject}
                              </span>{" "}
                            </h6>
                        <p className="text-xs text-secondary mb-0 color-gray">
                              {
                                 `New request for ${item.subject} `}
                            </p>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <div className="text-center">
                <p>No Notifications</p>
              </div>
            )}
          </div>
          <li onClick={handleClosePopup}>
            <div
              type="button"
              className="close ps-3"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img
                src="../assets/images/close-icon.svg"
                className="w-12px"
                alt="close-icon"
              />
            </div>
          </li>
        </div>
      </ul>
      <ul
        className="dropdown-menu row dropdown-menu-end gap-3 px-2 py-3 me-sm-n4 overflow-auto maxout overflow-auto z-index-2000 popup-notification-out"
        aria-labelledby="dropdownMenuButtons"
      >
        <li className="ps-3 d-flex justify-between align-items-center">
          <p className="text-pink text-bold mb-2">{`${localStorage.getItem(
            "firstName"
          )} `}</p>
        </li>
        <li
          onClick={(event) => handleUser(event)}
          data-bs-toggle="modal"
          data-bs-target="#ConfirmModal"
          className="ps-3 d-flex justify-between align-items-center mb-1"
        >
          <p>Logout</p>
          <i
            className="fa fa-sign-out cursor-pointer"
            aria-hidden="true"
          ></i>
        </li>
      </ul>
    </VDiv>
  );
}

export default function Dashboard() {
  const [logoutState, setLogoutState] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    setLogoutState(false);
    localStorage.clear();
    navigate("/");
  };
  const handleCancel = () => {
    setLogoutState(false);
  };
  const [refresh, setRefresh] = useState(false);

  const data = [
    {id : '1',
     tabTitle: "Service Requests",
     tabContent:<>
    <><ServiceRequestHistory refresh={refresh} /></>
   
   </>
    },
    {id : '2',
     tabTitle: "Product Requests",
     tabContent: <>
     <><ProductRequestHistory refresh={refresh}/></>
     </>
    }
  ]
  const [visibleTab, setVisibleTab] = useState(data[0].id)

  const listTitles = data.map((item) => 
  <li onClick={() => setVisibleTab(item.id)} className={visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"}>{item.tabTitle}</li>
)       
const listContent = data.map((item) => 
<p style={visibleTab === item.id ? {} : {display: 'none'}}>{item.tabContent}</p>)
  return (
    <Page
      customHeader={
        <Header
          title={"Service Requests"}
          logoutState={logoutState}
          setLogoutState={setLogoutState}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      }
    >
      <VDiv>
        {/* <SearchBox /> */}
        <HSpacer size="lg" />
        <div className="tabs">
                       <ul className="tabs-titles">
                         {listTitles}
                       </ul>
                      <div className="tab-content">
                         {listContent}
                       </div>
                   </div>
        {logoutState && (
          <ConfirmPopupModal
            header={"Are you sure u want to Logout ?"}
            id={"ConfirmModal"}
            confirmButtonLabel={"Logout"}
            confirmOnclick={logout}
            cancelOnclick={handleCancel}
          ></ConfirmPopupModal>
        )}{" "}
      </VDiv>
    </Page>
  );
}
