


import { getProductRequestApiUrl, getProductRequestByStatusApiUrl, getProductRequestDetailApiUrl, getProductRequestNewApiUrl, getProductRequestStatusesApiUrl, getUpdateProductRequestStatusApiUrl } from "./ApiUrls";
import { get, put } from "./Service";


export const getProductRequests = async (staffId) => {
    const response = await get(getProductRequestApiUrl(staffId));
    if (!response) {
        return []
    }
    return response;
};
export const getProductRequestByStatus = async (staffId, id) => {
    const response = await get(getProductRequestByStatusApiUrl(staffId, id));
    if (!response) {
        return []
    }
    return response;
};

export const getProductRequestsNew = async (staffId) => {
    const response = await get(getProductRequestNewApiUrl(staffId));
    if (!response) {
        return []
    }
    return response;
};


export const getProductRequestStatuses = async (staffId, tenantId) => {
    const response = await get(getProductRequestStatusesApiUrl(staffId, tenantId));
    return response;
};

export const getProductRequestDetail = async (staffId, id) => {
    const response = await get(getProductRequestDetailApiUrl(staffId, id));
    return response;
};

export const updateProductStatus = async (staffId, data) => {
    const response = await put(getUpdateProductRequestStatusApiUrl(staffId), data);
    return response;
};