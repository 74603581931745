import React from "react";
import { createContext, useState } from "react";


export const AppState = createContext()

export default function StateProvider({ children }) {

    const [appData, setAppData] = useState([])

    return (
        <AppState.Provider value={{ appData, setAppData }}>
            {children}
        </AppState.Provider>
    )
}