import  { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getProductRequestByStatus, getProductRequestStatuses, getProductRequests, getProductRequestsNew } from "../service/ProductRequestService";
import { getProductRequestDetailUrl } from "../Constant";

const useProductRequestHistory = (refresh) => {
    const { staffId } = useParams();

  const [list, setList] = useState([]);

  const navigate = useNavigate();

  const [statuses, setStatuses] = useState([]);

  const fetchServiceRequests = async (status, id) => {
    let response;
    if (id === "all") {
      response = await getProductRequests(staffId);
    } else if (id === "New") {
      response = await getProductRequestsNew(staffId);
    } else {
      response = await getProductRequestByStatus(staffId, status);
    }
    setList(response);
  };
  const fetStatuses = async () => {
    const response = await getProductRequestStatuses(
      staffId,
      localStorage.getItem("tenantId")
    );
    setStatuses(response);
  };

  const goToDetail = (item) => {
    navigate(getProductRequestDetailUrl(staffId, item.id));
  };

  const onFilterClick = (item) => {
    fetchServiceRequests(item.id, item?.displayName);
  };

  useEffect(() => {
    fetchServiceRequests("all", "all");
    fetStatuses(); // eslint-disable-next-line
  }, [staffId, refresh]);
  return {
      onFilterClick,
      goToDetail,
      statuses,
      list,

  }
};

export default useProductRequestHistory;
