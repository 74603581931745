import React from "react";

export default function Toaster({ text, className }) {
  return (
    <div className={`toast show${className ? " " + className : ""}`}>
      <p>
        <strong>{text}</strong>
      </p>
    </div>
  );
}
