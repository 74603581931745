import React, { useState } from "react";
import VDiv from "../containers/vdiv";



import PageTitle from "../elements/texts/page-title";
import { getFormattedDateAndTime } from "../../utils/formatters";
import HDiv from "../containers/hdiv";
import HGrow from "../elements/h-grow";
import Toaster from "../general/toast";
import RequestPopup from "../popups/request-popup";
import useProductRequestDetailSection from "../../hooks/useProductRequestDetailSection";

export default function ProductRequestDetailSection() {
  const {onUpdate,
    onCancel,
    showForm,
    serviceRequest,
    showMessage,
    message,
    statuses,setShowForm} = useProductRequestDetailSection()


  return (
    <VDiv>
      {showMessage && <Toaster text={message} className="success" />}
      {serviceRequest && (
        <>
          <VDiv className={"row"}>
            <HDiv>
              <PageTitle text={serviceRequest.product.name} />
              <HGrow />
              <button
                className={`edc-btn-secondary`}
                onClick={() => setShowForm(true)}
                disabled={serviceRequest?.status?.name === "DELIVERED"}
              >
                Update
              </button>
            </HDiv>
            <p>{serviceRequest.comment} </p>
            <div className="timeline timeline-one-side mt-3">
              {serviceRequest?.histories?.map((item) => (
                <StatusBlock key={item.id} item={item} />
              ))}
            </div>
          </VDiv>
          {showForm && (
            <RequestPopup
              title={serviceRequest.product.name}
              serviceRequest={serviceRequest}
              onCancel={onCancel}
              statuses={statuses}
              onSave={onUpdate}
            />
          )}
        </>
      )}
    </VDiv>
  );
}

function StatusBlock({ item }) {
  const [openFile, setOpenFile] = useState(false);

  const [selectedFile, setSelectedFile] = useState();

  const onFileClick = (file) => {
    setSelectedFile(file);
    setOpenFile(true);
  };

  const closeFile = (event) => {
    event.stopPropagation();
    setOpenFile(false);
  };


  
  return (
    <div className="timeline-block mb-3">
      <span className="timeline-step">
        <img src="/assets/images/icons/completed-icon.png" alt="Done" />
      </span>
      <div className="timeline-content">
        <h6 className="text-dark text-sm font-weight-bold mb-0">
          {item?.status?.displayName}
        </h6>
        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
          {getFormattedDateAndTime(new Date(item.createdOn))}
        </p>
        {item.comment && <p className="comment">{item.comment}</p>}
        <HDiv className="mt-3 mb-3">
          {item?.attachments?.map((attachment) => (
            <VDiv
              onClick={() => onFileClick(attachment)}
              className="file-preview"
              key={attachment.id}
              style={{
                backgroundImage: `url(${attachment.attachment.mediaUrl})`,
              }}
            ></VDiv>
          ))}
        </HDiv>
      </div>

      {openFile ? (
        <VDiv className="show-file-container">
          <img
            className="file"
            alt={selectedFile.attachment.name}
            src={selectedFile.attachment.mediaUrl}
          />
          <img
            className="close-btn"
            src="/assets/images/icons/icon-close-white.png"
            alt="close"
            onClick={(event) => closeFile(event)}
          />
        </VDiv>
      ) : null}
    </div>
  );
}
