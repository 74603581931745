import React from "react";

const ConfirmPopupModal = ({
  header,
  children,
  id,
  confirmButtonLabel,
  confirmOnclick,
  cancelOnclick
}) => {
  return (
    <>
      <div
        className="modal display-modal"
        id={id}
        tabIndex="1"
        role="dialog"
        aria-labelledby="ModalCenter"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered justify-content-center"
          role="document"
        >
          <div className="modal-content rounded-3 custom-modal">
            <div className="d-flex justify-content-between align-items-center px-3 pt-3">
              <p className="fw-bolder text-black my-0">{header} </p>
            </div>
            <div className="modal-body pb-0">{children}</div>
            <div className="d-flex justify-content-end p-3">
              <div className="d-flex gap-2 flex-wrap">
                <button
                  type="button"
                  className="btn  w-auto mb-0"
                  data-bs-dismiss="modal"
                  onClick={cancelOnclick}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  onClick={confirmOnclick}
                  className="btn btn-primary w-auto mb-0"
                >
                  {confirmButtonLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPopupModal;
