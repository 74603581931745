import React from 'react'

import VDiv from './vdiv'
import Text from '../elements/texts/text'

export default function FormContainer({title, subTitle, children, className, showLogo}) {
    return (
        <VDiv className={`form-container ${className ? className : ''}`}>
            <VDiv className="form">
                {showLogo ? <img className="form-logo mb-3" src="/assets/images/logo-rounded-alt.png" alt="Eductate Pro" />: null}
                <Text text={title} size="lg" className="mb-3 block" />
                <Text text={subTitle} size="md" className="mb-3 block" />
                <VDiv className="row">
                    {children}
                </VDiv>
            </VDiv>
        </VDiv>
    )
}