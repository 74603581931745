import React from 'react'
import AddButton from '../inputs/add-button'

export default function SectionHeader({title, showViewAll, onViewAll, showAddButton, onAdd, showAddText = false}) {
    return (
        <div className='flex header mb-1'>
            <span className='section-title'>{title}</span>
            <span className='grow'></span>
            {
                showAddButton && <AddButton alt="Add" onClick={onAdd} showText={showAddText} />
            }
            {
                showViewAll ? <span onClick={onViewAll} className='edu-link'>View all</span> : null
            }
        </div>
    )
}