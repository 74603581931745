import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VDiv from "../containers/vdiv";

import {
  getServiceRequests,
  getServiceRequestStatuses,
  getServiceRequestByStatus,
  getServiceRequestsNew,
} from "../../service/ServiceRequestService";
import {  getServiceRequestDetailUrl } from "../../Constant";
import Statuses from "./statuses";
import HSpacer from "../elements/h-spacer";
import RequestsCard from "../cards/requests-card";

export default function ServiceRequestHistory({ refresh }) {
  const { staffId } = useParams();

  const [list, setList] = useState([]);

  const navigate = useNavigate();

  const [statuses, setStatuses] = useState([]);
  const [requestLength,setRequestLength] = useState([])
  
  const fetchServiceRequests = async (status, id) => {

    
    let response;
    if (status === "all") {
      response = await getServiceRequests(staffId);
      
    }
     else if (id === "New") {
      response = await getServiceRequestsNew(staffId);
    } else {
      response = await getServiceRequestByStatus(staffId, status);
    }
    setList(response);
    
  };
  const fetStatuses = async () => {
    const response = await getServiceRequestStatuses(
      staffId,
      localStorage.getItem("tenantId")
    );
    setStatuses(response);
  };

  const goToDetail = (item) => {
    navigate(getServiceRequestDetailUrl(staffId, item.id));
  };

  const onFilterClick = (item) => {    
    fetchServiceRequests(item.id, item?.displayName);
  };


  
  const fetchInitially = async() =>{ 
    statuses.map(async function (status) {

      await getServiceRequestByStatus(staffId, status.id);
      const statusDataPromises = statuses.map(async (status) => {
        const response = await getServiceRequestByStatus(staffId, status.id);
        // Set the state for each specific status
        setRequestLength((prevState) => ({
          ...prevState,
          [status.id]: response,
        }));
      });

      // Wait for all statusDataPromises to resolve
      await Promise.all(statusDataPromises);



    });
  
  }
 

  
  console.log(requestLength,"list>>>");
  
  useEffect(() => {
    fetchServiceRequests("all", "all");
    fetStatuses(); 
    
   // eslint-disable-next-line
  }, [staffId, refresh]);

  useEffect(()=>{
    fetchInitially()
// eslint-disable-next-line
  },[])
  return (
    <VDiv>
      {/* <SectionHeader title="Service Requests" /> */}
      <Statuses list={statuses} requestLength={requestLength} onClick={onFilterClick} />
      <HSpacer size="sm" />
      <VDiv className={"row"}>
        {list.length > 0 ? (
          list.map((item) => {
            return (
              <VDiv className="col-md-4 mb-3" key={item.id}>
                <RequestsCard
                  item={item}
                  onClick={() => goToDetail(item)}
                  showStatus={false}
                />
              </VDiv>
            );
          })
        ) : (
          <p>No requests in this Section</p>
        )}
      </VDiv>
    </VDiv>
  );
}
