import SearchBox from "../components/layout/search-box";
import HSpacer from "../components/elements/h-spacer";
import VDiv from "../components/containers/vdiv";
import Page from "../components/general/page";
import TabBar from "../components/layout/tab-bar"
import ServiceRequestHistory from "../components/sections/service-request-history"

export default function ServiceHistory() {  
    return (
      <Page showHeader title={"Service request history"} >
        <VDiv>
          <SearchBox placeholder={"Search services..."} />
          <HSpacer size="lg" />
          <ServiceRequestHistory />
        </VDiv>
        <TabBar selectedIndex={2}/>
      </Page>
    );
  }