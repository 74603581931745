import { HashRouter, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import ServiceHistory from "./pages/ServiceHistory";
import Explore from "./pages/Explore";
import Chat from "./pages/Chat";
import More from "./pages/More";
import ServiceRequestDetail from "./pages/ServiceRequestDetail";
import Login from "./pages/Login";
import { getFirebaseToken, onForegroundMessage } from "./firebase";
import { useContext, useEffect, useState } from "react";
import Toast, {
    toaster,
} from "./components/notification-popup/notification-popup";
import RequireAuth from "./utils/requireAuth";
import { AppState } from "./components/state";
import ProductRequestDetail from "./pages/ProductRequestDetail";

export const handleGetFirebaseToken = () => {
    const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isiOS === false) {
        if (localStorage.getItem("token")) {
            return getFirebaseToken()
                .then((firebaseToken) => {
                    localStorage.setItem("notifyToken", firebaseToken);
                    return firebaseToken;
                })
                .catch((err) =>
                    console.error(
                        "An error occurred while retrieving Firebase token. ",
                        err
                    )
                );
        }
    }
};

function App() {
    const { setAppData } = useContext(AppState);
    const [notifications, setNotifications] = useState([]);
    const [alert, setAlert] = useState(false);

    const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const [notificationPermission, setNotificationPermission] = useState(isiOS === false ? Notification.permission : "")


    useEffect(() => {
        if (isiOS === false) {
            if (Notification.permission !== "granted") {
                requestNotificationPermission();
            } else if (Notification.permission === "granted") {
                handleGetFirebaseToken();
            }
        }// eslint-disable-next-line
    }, [notificationPermission]);
    const requestNotificationPermission = async () => {
        try {
            const permission = await Notification.requestPermission();
            setNotificationPermission(permission);
        } catch (error) {
            console.error("Error requesting notification permission:", error);
        }
    };
    useEffect(() => {
        if (isiOS === false) {
            if ("serviceWorker" in navigator) {
                navigator.serviceWorker
                    .register("/firebase-messaging-sw.js") // Path to your service worker file
                    .then((registration) => { })
                    .catch((error) => {
                        console.error("Error registering service worker:", error);
                    });
            }
            // Listen for messages from the service worker
            navigator.serviceWorker.addEventListener("message", handleMessage);
            // return () => {
            //   // Clean up the event listener when the component unmounts
            //   navigator.serviceWorker.addEventListener('message', handleMessage);
            // };
        }
        // eslint-disable-next-line
    }, []);
    const handleMessage = (event) => {
        if (event.data && event.data.type === "background-message") {
            // const { title, body, data } = event.data;
            // const newNotification = { title, body, data };
            // setNotifications((prevNotifications) => [
            //     ...prevNotifications,
            //     newNotification,
            // ]);
        }
    };
    // useEffect(() => {
    //     if (isiOS === false) {
    //         setTimeout(function () {
    //             document.getElementById("liveToast").classList.replace("show", "hide");
    //         }, 5000);
    //     }// eslint-disable-next-line
    // }, [notifications]);

    setTimeout(function () {
                    document.getElementById("liveToast").classList.replace("show", "hide");
                }, 3000);
    useEffect(() => {
        if (isiOS === false) {
            setAppData(notifications);
        }
        // eslint-disable-next-line
    }, [notifications]);

    useEffect(() => {
        if (isiOS === false) {
            try {
                onForegroundMessage((payload) => {
                    const {
                        notification: { title, body }, // eslint-disable-next-line
                        data,
                    } = payload;
                    const newNotification = { title, body, data };

                    if (data.userId === localStorage.getItem("userId")) {
                        setNotifications([newNotification]);
                        toaster("success", title, body);
                        setAlert(true);
                    }
                });
            } catch (err) {
                console.error(
                    "An error occurred while setting up foreground message listener. ",
                    err
                );
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setTimeout(() => {
          setAlert(false);
        }, 500);
      }, [alert]);
    return (
        <>
            <Toast />
            <HashRouter>
                <Routes>
                    <Route element={<Login />} path="/" />
                    <Route
                        element={
                            <RequireAuth>
                                <Dashboard alert={alert}/>
                            </RequireAuth>
                        }
                        path="/s/:staffId"
                    />
                    <Route
                        element={
                            <RequireAuth>
                                <ServiceHistory />
                            </RequireAuth>
                        }
                        path="/t/:tenantId/r/:roomId/service-history"
                    />
                    <Route
                        element={
                            <RequireAuth>
                                <Explore />
                            </RequireAuth>
                        }
                        path="/t/:tenantId/r/:roomId/explore"
                    />
                    <Route
                        element={
                            <RequireAuth>
                                <Chat />
                            </RequireAuth>
                        }
                        path="/t/:tenantId/r/:roomId/chat"
                    />
                    <Route
                        element={
                            <RequireAuth>
                                <More />
                            </RequireAuth>
                        }
                        path="/t/:tenantId/r/:roomId/more"
                    />
                    <Route
                        element={
                            <RequireAuth>
                                <ServiceRequestDetail />
                            </RequireAuth>
                        }
                        path="/s/:staffId/service-request/:id"
                    />
                    <Route
                        element={
                            <RequireAuth>
                                <ProductRequestDetail />
                            </RequireAuth>
                        }
                        path="/s/:staffId/product-request/:id"
                    />
                </Routes>
            </HashRouter>
        </>
    );
}

export default App;
