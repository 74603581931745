
//const HOST = "http://13.232.191.34:10020"
//const HOST = "http://localhost:10020"

const HOST = "https://dev-api-concierge.gjglobalsoft.com"

export const BASE_AUTH_URL = `${HOST}/`;

const BASE_ATTACHMENT_URL = `${HOST}/v1/staff/attachment`;

const BASE_CONCIERGE_URL = `${HOST}/v1/staff/`;

export const LOGIN = `${BASE_AUTH_URL}oauth/token`;

export const UPLOAD_URL = `${BASE_ATTACHMENT_URL}`;

export const getUploadApiUrl = (tenantId) => {
    return BASE_ATTACHMENT_URL
}

export const getAllNotificationUrl = (id) => {
    return `${BASE_AUTH_URL}v1/notification-history/${id}`;
  };
  
export const getCategoriesApiUrl = (tenantId, locationId) => {
    return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category`
}

export const getServicesByCategoryApiUrl = (tenantId, locationId, categoryId) => {
    return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category/${categoryId}/services`
}

export const getCategoryApiUrl = (tenantId, locationId, categoryId) => {
    return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category/${categoryId}`
}

export const getServicesApiUrl = (tenantId, locationId) => {
    return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/services`
}

export const getRoomServicesApiUrl = (tenantId, roomId) => {
    return `${BASE_CONCIERGE_URL}${tenantId}/room/${roomId}/room-service`
}

export const getServiceRequestApiUrl = (staffId) => {
    return `${BASE_CONCIERGE_URL}${staffId}/service-request`
}
export const getProductRequestApiUrl = (staffId) => {
    return `${BASE_CONCIERGE_URL}${staffId}/product-request`
}
export const getServiceRequestNewApiUrl = (staffId) => {
    return `${BASE_CONCIERGE_URL}${staffId}/service-request/new`
}
export const getProductRequestNewApiUrl = (staffId) => {
    return `${BASE_CONCIERGE_URL}${staffId}/product-request/new`
}
export const getServiceRequestByStatusApiUrl = (staffId, id) => {
    return `${BASE_CONCIERGE_URL}${staffId}/service-request/status/${id}`
}
export const getProductRequestByStatusApiUrl = (staffId, id) => {
    return `${BASE_CONCIERGE_URL}${staffId}/product-request/status/${id}`
}

export const getServiceRequestDetailApiUrl = (staffId, id) => {
    return `${BASE_CONCIERGE_URL}${staffId}/service-request/${id}`
}
export const getProductRequestDetailApiUrl = (staffId, id) => {
    return `${BASE_CONCIERGE_URL}${staffId}/product-request/${id}`
}

export const getUpdateServiceRequestStatusApiUrl = (staffId, id) => {
    return `${BASE_CONCIERGE_URL}${staffId}/service-request/${id}/status`
}
export const getUpdateProductRequestStatusApiUrl = (staffId, id) => {
    return `${BASE_CONCIERGE_URL}${staffId}/product-request/${id}/status`
}

export const getServiceRequestStatusesApiUrl = (staffId, tenantId) => {
    return `${BASE_CONCIERGE_URL}${staffId}/service-request/status/tentant/${tenantId}`
}
export const getProductRequestStatusesApiUrl = (staffId, tenantId) => {
    return `${BASE_CONCIERGE_URL}${staffId}/product-request/status/tentant/${tenantId}`
}

export const getStaffDetailsApiUrl = (id) => {
    return `${BASE_CONCIERGE_URL}user/${id}`
}

// export const NOTIFY_TOKEN_URL = `${BASE_AUTH_URL}notification/v1/notification-tokens`;
export const NOTIFY_TOKEN_URL = `${BASE_AUTH_URL}v1/notification-tokens`;