
import VDiv from "../components/containers/vdiv";
import Page from "../components/general/page";
import ProductRequestDetailSection from "../components/sections/product-request-detail-section";

export default function ProductRequestDetail() {  
    return (
      <Page showHeader title={"Product request detail"} showBackNavigation={true} >
        <VDiv>
          <ProductRequestDetailSection />
        </VDiv>
      </Page>
    );
  }