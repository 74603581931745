import React, { useState } from "react";
import EdcCarousel from "./carousel";
import StatusCard from "../cards/status-card";

export default function Statuses({ list, onClick,requestLength }) {
  const [selectedItem, setSelectedItem] = useState("all");
  const onViewAll = () => { };

  const handleClick = (item) => {
    setSelectedItem(item.id);
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <EdcCarousel onViewAll={onViewAll}>
      <StatusCard
        selected={selectedItem === "all"}
        item={{ name: "all", displayName: "All", id: "all" }}
        onClick={() => handleClick({ displayName: "all", id: "all" })}
      />
      {list.map((item) => (
        (item.name !== "CLOSED") && (item.name !== "NEW") &&(
          <StatusCard
            selected={selectedItem === item.id}
            item={item}
            key={item.id}
            requestLength={requestLength}
            onClick={() => handleClick(item)}
          />
        )
      ))}
    </EdcCarousel>
  );
}
