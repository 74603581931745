import React from "react";
import VDiv from "../containers/vdiv";


import Statuses from "./statuses";
import HSpacer from "../elements/h-spacer";
import RequestsCard from "../cards/requests-card";
import useProductRequestHistory from "../../hooks/useProductRequestHistory";

export default function ProductRequestHistory({ refresh }) {
  const { onFilterClick,
    goToDetail,
    statuses,
    list,} = useProductRequestHistory(refresh)
  return (
    <VDiv>
      {/* <SectionHeader title="Service Requests" /> */}
      <Statuses list={statuses} onClick={onFilterClick} />
      <HSpacer size="sm" />
      <VDiv className={"row"}>
        {list.length > 0 ? (
          list.map((item) => {
            return (
              <VDiv className="col-md-4 mb-3" key={item.id}>
                <RequestsCard
                  item={item}
                  onClick={() => goToDetail(item)}
                  showStatus={false}
                />
              </VDiv>
            );
          })
        ) : (
          <p>No requests in this Section</p>
        )}
      </VDiv>
    </VDiv>
  );
}
