import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { login, postNotificationToken } from "../service/LoginService";

import { getStaffDetails } from "../service/StaffService";

import { useForm } from "react-hook-form";
import { handleGetFirebaseToken } from "../App";
import axios from "axios";

export default function useLogin() {
  const navigate = useNavigate();
  const methods = useForm();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiInProgress, setApiInProgress] = useState(false);
  // eslint-disable-next-line
  const [notifyToken, setNotifyToken] = useState({});

  const onSubmit = methods.handleSubmit((data) => {
    doLogin(data);
  });

  const onInputChange = (event) => {
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  };

  const handleLoginResponse = async (response) => {
    if (response) {
      if (response.status === 200) {
        const data = response.data;
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("firstName", data.firstName);
        localStorage.setItem("lastName", data.lastName);
        localStorage.setItem("userId", data.id);
        localStorage.setItem("email", data.email);
        localStorage.setItem("locationId", data.locationId);
        if (data.access_token) {
          //navigate(`/t/${data.tenantId}/l/${data.locationId}`);
          fetchStaffDetails(data.id);
        }
      } else if (response.status === 401) {
        setErrorMessage("Invalid credentials.");
        setShowErrorMessage(true);
      }
    } else {
      setErrorMessage("Something went wrong. Try again later.");
      setShowErrorMessage(true);
    }
  };

  const fetchStaffDetails = async (id) => {
    const response = await getStaffDetails(id);
    localStorage.setItem("tenantId", response.tenantId);

    navigate(`/s/${response.id}`);
    await handleGetFirebaseToken();
    notifyToken.userId = localStorage.getItem("userId");
    notifyToken.departmentId = response.department.id;
    notifyToken.token = localStorage.getItem("notifyToken");
    notifyToken.deviceId = "";
    notifyToken.role = "STAFF";
    postNotificationToken(notifyToken);
    // eslint-disable-next-line 
    const result = await axios.post(
      "https://dev-api-concierge.gjglobalsoft.com/v1/notification-tokens",
      notifyToken
    );
  };

  const doLogin = async (data) => {
    setApiInProgress(true);
    const formData = new FormData();
    formData.append("grant_type", "password");
    formData.append("username", data.email.toLowerCase());
    formData.append("password", data.password);
    localStorage.clear();
    const response = await login(formData);
    if (response.data.userType === "USER") {
      await handleLoginResponse(response);
    } else {
      setErrorMessage("No Users Found");
      setShowErrorMessage(true);
    }
    setApiInProgress(false);
  };

  return {
    onSubmit,
    methods,
    errorMessage,
    showErrorMessage,
    onInputChange,
    apiInProgress,
  };
}
